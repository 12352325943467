export default theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  value: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    wordBreak: 'break-all',
    marginTop: 'auto'
  },
  overflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});
