import { makeStyles } from '@mui/styles';
import NRSuperIndexAndValueStyles from './NRSuperIndexAndValue.styles';
import clsx from 'clsx';
import UpperCaseGreyText from '../UpperCaseGreyText/UpperCaseGreyText';
import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
const useStyles = makeStyles(NRSuperIndexAndValueStyles);

function NRSuperIndexAndValue({ superIndex, value, extraClass }) {
  const classes = useStyles();
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const divElement = document.getElementById(`value-container-${superIndex}`);
    setIsOverflowing(divElement.scrollWidth > divElement.clientWidth);
  }, []);

  return (
    <div className={clsx(classes.mainContainer, extraClass)}>
      <UpperCaseGreyText text={superIndex} />
      <Tooltip title={isOverflowing ? value : ''}>
        <div id={`value-container-${superIndex}`} className={clsx(classes.value, classes.overflow)}>
          {value}
        </div>
      </Tooltip>
    </div>
  );
}
export default NRSuperIndexAndValue;
