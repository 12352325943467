import { alpha, createTheme } from '@mui/material';

const Neutral = {
  100: '#E1E2E5',
  200: '#C2C4C9',
  300: '#A3A5AD',
  400: '#848791',
  500: '#646975',
  600: '#454A59',
  700: '#262C3D',
  800: '#131A2C',
  900: '#0A0D16'
};

const NRed = {
  50: '#F1DFE1',
  100: '#F0C1C4',
  200: '#DF8E8B',
  300: '#D56956',
  400: '#E04B41',
  500: '#E33B26',
  600: '#D33125',
  700: '#C1231F',
  800: '#B51E18',
  900: '#A7110A',
  A100: '#F18172',
  A200: '#EE4842',
  A400: '#EF0235',
  A700: '#C40000'
};

const NBlue = {
  50: '#E1F6FF',
  100: '#BAE0FF',
  200: '#91CEFF',
  300: '#67B9FF',
  400: '#4CA7FF',
  450: '#3F97FF',
  500: '#3699FF',
  600: '#3389EE',
  700: '#3377DC',
  800: '#2D66C9',
  900: '#2748AA',
  A100: '#8AB1FF',
  A200: '#5B89FF',
  A400: '#5178FF',
  A700: '#535FFF'
};

const NOrange = {
  50: '#FEF7EB',
  100: '#FFE6BE',
  200: '#FFD18D',
  300: '#FDBC58',
  400: '#FDAC34',
  500: '#FF9F1C',
  600: '#FA9317',
  700: '#F58416',
  800: '#EE7514',
  900: '#E65B12',
  A100: '#FED68A',
  A200: '#FCB04B',
  A400: '#FE9618',
  A700: '#FD740F'
};

const NGreen = {
  50: '#E6FFFF',
  100: '#B8FFFD',
  200: '#85FFDF',
  300: '#4EECC0',
  400: '#22DCAB',
  500: '#00CC99',
  600: '#00BC8A',
  700: '#10AC7B',
  800: '#009B6D',
  900: '#047C4F',
  A100: '#C0FFFD',
  A200: '#91FFDE',
  A400: '#6EFFB0',
  A700: '#41F5AC'
};

const NViolet = {
  500: '#6929c4'
};

const NPink = {
  500: '#FF004D'
};

const NRWeight1 = {
  50: '#e0f2f1',
  100: '#b2dfdb',
  200: '#80cbc3',
  300: '#4db6ab',
  400: '#26a699',
  450: '#15A696',
  500: '#009687',
  600: '#00897a',
  700: '#00796a',
  800: '#00695b',
  900: '#004d3f'
};

const NRWeight2 = {
  50: '#fef4e2',
  100: '#fee2b6',
  200: '#fdcf87',
  300: '#fdbb58',
  400: '#fdac37',
  450: '#FCB24D',
  500: '#fd9e1f',
  600: '#f9921d',
  700: '#f28319',
  800: '#ec7316',
  900: '#e35a11'
};

const NRWeight3 = {
  50: '#fbe9e7',
  100: '#ffccbc',
  200: '#ffaa91',
  300: '#ff8965',
  350: '#FF7E5A',
  400: '#ff6f43',
  500: '#ff5523',
  600: '#f44f1f',
  700: '#e6481a',
  800: '#d84116',
  900: '#bf340d'
};

const NRWeight4 = {
  50: '#ffebed',
  100: '#ffcdce',
  200: '#f09a94',
  300: '#e6736a',
  400: '#ee5444',
  500: '#f14625',
  600: '#e33c26',
  650: '#E92F50',
  700: '#d13221',
  800: '#c42b19',
  900: '#b61f0b'
};

const WHITE = '#fff';
const BLACK = '#000';
const BLUE_LINK = '#4ED4FF';
const BLUE_LINK_TOOLTIP = '#4ED4FF';
const CHART_DATA_LABELS = '#304758';
const TOOLTIP = '#515663';
const BACKGROUND_DEFAULT = '#131A2C';

export const NRTheme = {
  typography: {
    fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '-apple-system'].join(','),
    h1: {
      fontFamily: 'Exo',
      fontSize: '48px',
      fontWeight: 700,
      lineHeight: 1.16,
      letterSpacing: '-0.01em'
    },
    h2: {
      fontFamily: 'Exo',
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: 1.5
    },
    h3: {
      fontFamily: 'Exo',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: 1.55
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: '0.01em'
    },
    body1: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: 1.5,
      letterSpacing: '0.01em'
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: 1.42,
      letterSpacing: '0.01em'
    },
    body2: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.42,
      letterSpacing: '0.01em'
    },
    code: {
      fontFamily: 'Roboto Mono',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.42
    },
    label: {
      fontFamily: 'Exo',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: 1.66,
      letterSpacing: '0.02em',
      textTransform: 'uppercase'
    },
    caption: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: 1.33,
      letterSpacing: '0.04em'
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#FF0101',
      contrastText: 'rgba(255,255,255,0.87)'
    },
    secondary: {
      main: '#4ED4FF',
      contrastText: 'rgba(0,0,0,0.87)'
    },
    green: {
      ...NGreen,
      contrastText: BLACK
    },
    blue: {
      ...NBlue,
      link: BLUE_LINK,
      linkTooltip: BLUE_LINK_TOOLTIP,
      contrastText: WHITE
    },
    neutral: Neutral,
    white: WHITE,
    black: BLACK,
    red: { ...NRed, contrastText: BLACK },
    chartDataLabels: CHART_DATA_LABELS,
    pink: NPink,
    purple: '#9F31A9',
    violet: NViolet,
    text: {
      primary: 'rgba(255,255,255,0.87)',
      primaryAlt: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(255,255,255,0.60)',
      disabled: 'rgba(255,255,255,0.32)',
      icon: 'rgba(255,255,255,0.60)', // iconActionable
      iconInfo: 'rgba(255,255,255,0.48)',
      iconAlt: 'rgba(255,255,255,0.60)',
      avatarAlt: { background: Neutral[700], color: 'rgba(255, 255, 255, 0.6)' }, // temporarily until appBar is restyled
      grayBadge: { background: Neutral[200], color: 'rgba(0,0,0,0.87)' },
      border: 'rgba(255,255,255,0.24)'
    },
    input: {
      border: 'rgba(255,255,255,0.3)'
    },
    backgroundAlt: {
      paper: '#262C3D',
      color: 'rgba(255,255,255,0.87)',
      colorSecondary: 'rgba(255,255,255,0.6)'
    },
    divider: '#414655',
    tooltip: {
      background: alpha(TOOLTIP, 0.9),
      color: 'rgba(255,255,255,0.87)'
    },
    background: {
      default: BACKGROUND_DEFAULT,
      paper: '#1C2334'
    },
    orange: {
      // probably can be removed
      ...NOrange,
      contrastText: WHITE
    },
    action: {
      // activatedOpacity: 0.24,
      // active: '#fff',
      // disabled: 'rgba(255, 255, 255, 0.3)',
      // disabledBackground: 'rgba(255, 255, 255, 0.12)',
      // disabledOpacity: 0.38,
      // focus: 'rgba(255, 255, 255, 0.12)',
      // focusOpacity: 0.12,
      hover: 'rgba(255, 255, 255, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(255, 255, 255, 0.08)',
      selectedOpacity: 0.08
    },
    weightedColors: {
      0: Neutral,
      1: NBlue,
      2: NRWeight1,
      3: NRWeight2,
      4: NRWeight3,
      5: NRWeight4
    },
    riskMeter: {
      background: 'rgba(255,255,255,0.2)'
    },
    error: {
      light: '#FF7A7A',
      main: '#FF5959',
      dark: '#B23E3E',
      contrastText: BLACK
    },
    warning: {
      light: '#FFAC33',
      main: '#FF9800',
      dark: '#b26a00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      light: '#5EADFF',
      main: '#3699FF',
      dark: '#256BB2',
      background: 'rgba(54, 153, 255, 0.1)',
      contrastText: WHITE
    },
    success: {
      light: '#63B190',
      main: '#3C9E75',
      dark: '#2A6E51',
      contrastText: BLACK
    },
    //Palette references: https://www.figma.com/file/ccO6qOh9tUDoyjKv2G6QMx/Turbine-Design-System?node-id=419%3A1592&t=Ub3s0Hn1OS0Msm6m-0
    customPalette: ['#569FE5', '#9268F7', '#30CCCC', '#F7CE6B', '#ED6D86', '#F2A354', '#74DE8B', '#A5E6DF', '#F880CF', '#E2E462'],
    severityPaletteArray: ['#818181', '#19859C', '#0E7469', '#B07C35', '#B2583E', '#A32038'], // red represents danger or error; orange represents a serious warning; yellow represents a regular warning, and green represents low or normal. Gray=undetermined, Blue=Negligible
    severityPalette: [
      // light = shade background
      {
        alt: '#CCCCCC',
        main: '#AAAAAA'
      },
      {
        alt: '#50CBE5',
        main: '#25BEDF'
      },
      {
        alt: '#43B7AB',
        main: '#15A696'
      },
      {
        alt: '#FCC170',
        main: '#FCB24D'
      },
      {
        alt: '#FF977B',
        main: '#FF7E5A'
      },
      {
        alt: '#F36D86',
        main: '#E92F50'
      }
    ], // red represents danger or error; orange represents a serious warning; yellow represents a regular warning, and green represents low or normal. Gray=undetermined, Blue=Negligible
    statusPalette: ['#00C58F', '#FF004D', '#B9B9B9', '#3F97FF'], //Green=Positive, Red=Negative, Gray=Not Applicable, Blue=OK
    statusPaletteWithOpacity: ['#00BC8A', '#E33B26', '#B9B9B9', '#3F97FF'], //Green=Positive, Red=Negative, Gray=Not Applicable, Blue=OK
    noData: {
      color: Neutral[400],
      background: Neutral[600],
      donut: Neutral[600]
    },
    elevation: {
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))'
    }
  },
  direction: 'ltr',
  components: {
    MuiAlert: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        outlined: ({ theme }) => ({
          background: alpha(theme.palette.error.main, 0.16),
          color: theme.palette.text.primary
        }),
        icon: ({ theme }) => ({
          marginRight: theme.spacing(1)
        }),
        message: ({ theme }) => ({
          ...theme.typography.body2
        })
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.3)',
          color: theme.palette.text.primary,
          backgroundImage: 'unset'
        })
      }
    },
    MuiTooltip: {
      defaultProps: {
        placement: 'top'
      },
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.tooltip.background,
          maxWidth: '600px',
          maxHeight: '300px',
          overflowY: 'auto',
          overflowX: 'hidden'
        })
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiGrid-spacing-xs-2 > .MuiGrid-item': {
            padding: theme.spacing(1)
          }
        })
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 600,
          '&.Pagination-text': {
            // @devexpress pagination
            color: `${theme.palette.text.secondary} !important`
          },
          '&:hover': {
            boxShadow: 'unset'
          },
          textTransform: 'unset'
        }),
        containedPrimary: ({ theme }) => ({
          color: theme.palette.getContrastText(theme.palette.primary.main)
        }),
        contained: {
          boxShadow: 'unset'
        }
      }
    },
    MuiButtonGroup: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 4
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 4
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-completed': {
            color: theme.palette.secondary.main
          },
          '&.Mui-active': {
            color: theme.palette.secondary.main
          }
        }),
        text: ({ theme }) => ({
          fill: theme.palette.getContrastText(theme.palette.primary.main)
        })
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          width: '0.5em',
          height: '0.5em'
        },
        '*::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.08)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.08)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.12)',
          borderRadius: 4
        },
        '*::-webkit-scrollbar-corner': { background: BACKGROUND_DEFAULT }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)'
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)'
          }
        }
      }
    },
    MuiTreeItem: {
      styleOverrides: {
        content: {
          '&.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)'
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '& > .MuiCheckbox-root > .MuiSvgIcon-root': {
            fill: 'inherit'
          },
          borderBottom: 'unset'
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary'
      },
      styleOverrides: {
        root: {
          '&>.MuiSvgIcon-root': {
            fill: 'currentColor !important'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-disabled': {
            color: theme.palette.text.disabled
          },
          color: theme.palette.text.icon,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          },
          borderRadius: 4
        })
      }
    },
    MuiAvatar: {
      defaultProps: {
        variant: 'rounded'
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'unset',
          boxShadow: 'unset'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: 'unset'
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: { elevation: 3 }
      }
    },
    MuiMenu: {
      defaultProps: {
        elevation: 3
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          borderRadius: 4
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        action: ({ theme }) => ({
          color: theme.palette.text.icon
        })
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset'
        }
      }
    }
  }
};

const customThemes = createTheme(NRTheme);
customThemes['NRResponsiveDimensions'] = {
  breadcrumb: {
    height: 30,
    marginBottom: 12
  },
  tabs: {
    height: 73,
    marginBottom: 10
  },
  menuContent: {
    padding: customThemes.spacing(2),
    [customThemes.breakpoints.down('lg')]: {
      paddingTop: customThemes.spacing(2),
      padding: customThemes.spacing(2, 1, 2, 1)
    }
  },
  downloadContainer: {
    minHeight: customThemes.spacing(4)
  }
};
customThemes['apexchartsOverrides'] = {
  '& .apexcharts-menu': {
    backgroundColor: customThemes.palette.background.paper,
    borderColor: customThemes.palette.divider,
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))'
  },
  '& .apexcharts-menu .apexcharts-menu-item:hover': {
    backgroundColor: customThemes.palette.action.hover
  },
  '& .apexcharts-tooltip ': {
    boxShadow: 'unset'
  },
  '& .apexcharts-tooltip.apexcharts-theme-dark': {
    backgroundColor: customThemes.palette.tooltip.background,
    ...customThemes.typography.caption
  },
  '& .apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title': {
    background: 'unset',
    ...customThemes.typography.caption,
    borderBottom: 'unset',
    padding: customThemes.spacing(0.5, 1, 0, 1),
    marginBottom: customThemes.spacing(0)
  },
  '& .apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-series-group': {
    padding: customThemes.spacing(0.5, 1, 0.5, 1)
  },
  '& .apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-text': {
    ...customThemes.typography.caption
  },
  '& .apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-text .apexcharts-tooltip-y-group': {
    padding: 'unset'
  },
  '& .apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-text-y-value': {
    fontWeight: customThemes.typography.fontWeightBold
  }
};
customThemes['highChartsOverrides'] = {
  '& .highcharts-contextmenu .highcharts-menu > hr': {
    border: `solid 1px ${customThemes.palette.divider}`
  }
};

customThemes['JsonViewer'] = {
  scheme: 'netrise',
  author: 'netrise',
  base00: customThemes.palette.background.default,
  base01: '#252525',
  base02: '#464646',
  base03: '#525252',
  base04: '#ababab',
  base05: '#b9b9b9',
  base06: '#e3e3e3',
  base07: customThemes.palette.blue[500],
  base08: '#7c7c7c',
  base09: '#999999',
  base0A: '#a0a0a0',
  base0B: '#8e8e8e',
  base0C: '#868686',
  base0D: '#686868',
  base0E: '#747474',
  base0F: '#5e5e5e'
};

customThemes['chip'] = {
  color: customThemes.palette.text.secondary,
  backgroundColor: customThemes.palette.neutral[700]
};

export const invertedThemeDark = createTheme({
  ...NRTheme,
  palette: {
    secondary: {
      main: '#FF0101',
      contrastText: 'rgba(255,255,255,0.87)'
    },
    primary: {
      main: '#4ED4FF',
      contrastText: 'rgba(0,0,0,0.87)'
    }
  }
});

export default customThemes;
